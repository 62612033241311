import React from "react"
import { Parallax } from "react-parallax"

import { Container, Row, Col } from "reactstrap"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import { parseOptions } from '../utils/praseOptions';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import { graphql, Link } from 'gatsby'
import MenuErlebnisse from '../components/menu/menu'
import AktuellMessage from '../components/aktuellMessage'
import LeafletMap from '../components/leafletMap'
import Footer from '../components/footer'
import useWindowSize from '../utils/useWindowsSize'


import arabica from "../images/chappi/arabicaa.png"
import rob from "../images/chappi/robusta_chappi.png"
import blend from "../images/chappi/blend_chappi.png"

import Parallax1 from '../images/chappi/02.jpg'
import Parallax1Mobile from '../images/chappi/mob/02.jpg'

// import Parallax2 from '../images/hofladen_fahr-erlebnis.jpeg'
// import Parallax2Mobile from '../images/parallax2-mobile.jpg'

import Parallax3 from '../images/chappi/03.jpg'
import Parallax3Mobile from '../images/chappi/mob/03.jpg'

import Parallax4 from '../images/chappi/04.jpg'
import Parallax4Mobile from '../images/chappi/mob/04.jpg'

import Parallax5 from '../images/chappi/05.jpg'
import Parallax5Mobile from '../images/chappi/mob/05.jpg'

import Parallax6 from '../images/chappi/06.jpg'
import Parallax6Mobile from '../images/chappi/mob/06.jpg'

import Parallax7 from '../images/chappi/07.jpg'
import Parallax7Mobile from '../images/chappi/mob/07.jpg'

import Parallax8 from '../images/chappi/08.jpg'
import Parallax8Mobile from '../images/chappi/mob/08.jpg'

import Parallax9 from '../images/chappi/09.jpg'
import Parallax9Mobile from '../images/chappi/09.jpg'

import Parallax10 from '../images/chappi/8_2_robusta.jpg'
import Parallax10Mobile from '../images/chappi/mob/8_2_robusta.jpg'

import Parallax11 from '../images/chappi/8_3_blend.jpg'
import Parallax11Mobile from '../images/chappi/mob/8_3_blend.jpg'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

const IndexPage = ({ data: {
  coffeeShop,
  // allContentfulContainerTexteHomepage,
  // allContentfulAktuellBannerFahrErlebnisCh
}}) => {
  // const content = allContentfulContainerTexteHomepage?.nodes?.[0]
  const { width } = useWindowSize()

  return (
    <Layout>
    <SEO title="Home" />
    
    <Hero fullScreen subtitle="Qualitätskaffee aus Vietnam" image={'../images/chappi/01.jpg'} />

    {/* {allContentfulAktuellBannerFahrErlebnisCh?.nodes && (
      <AktuellMessage {...allContentfulAktuellBannerFahrErlebnisCh?.nodes[0]} />
    )} */}

    <Container style={{ marginTop: '60px' }}>
      <Row>
        <Col xl="12" lg="12">
          {/* <p className="aktuell">Aktuell: {allContentfulAktuell?.nodes?.[0]?.aktuell} </p>
          <br></br> */}
          <p style={{fontWeight:'700', fontSize:'3rem'}} className="cont-desc-1">
          


          Coming soon - stay tuned..! <br/>



          </p>
        </Col>
      </Row>
    </Container>

    <Parallax
      strength={300}
      className="react-parallax"
      bgImage={width < 768 ? Parallax1Mobile : Parallax1}
      bgImageAlt='Aktuell'
    />

    {/* <div className="parallax1"></div> */}

    {/* <MenuErlebnisse /> */}

    {/* <div className="parallax2"></div> */}

    {/* <Parallax
      strength={300}
      className="react-parallax"
      bgImage={width < 768 ? Parallax2Mobile : Parallax2}
      bgImageAlt='Aktuell'

    /> */}

    <Container>
      <Row>
        <Col xl="6" lg="6">
        <h2 className="sec-titel" > Qualitätskaffee aus Vietnam - Fair. Sozial. Nachhaltig </h2>
        </Col>
        
        <Col xl="6" lg="6">
          <p className="cont-desc">
          Das Schweizer Herzensprojekt im zentralen Hochland Vietnams, 
          von der Vietnamesin Thao Nguyen und dem Schweizer Marc Zihlmann initiiert, wurde bereits mehrfach
           für das sozial engagierte und nachhaltige Geschäftsmodell ausgezeichnet. 
          Thao und Marc sind im Kaffeeland Vietnam als Paar stark im alternativen Kaffeegeschäft engagiert. 
          </p>
        </Col>
      </Row>
    </Container>
        
    {/* <div className="parallax3"></div> */}
    <Parallax
      strength={300}
      className="react-parallax"
      bgImage={width < 768 ? Parallax3Mobile : Parallax3}
      bgImageAlt='Aktuell'

    />

    <Container>
      <Row>
        <Col xl="6" lg="6">
          <h2 className="sec-titel">Rainforest Alliance zertifiziert </h2>
        </Col>
        
        <Col xl="6" lg="6">
          <p className="cont-desc">
          Die nach Rainforest Alliance Standards zertifizierten Qualitätskaffees
           werden von den lokalen Kaffeebauern mit viel Leidenschaft angebaut und sorgfältig von Hand gepflückt. 
          Die schonende traditionelle Trommelröstung sorgt für ein unvergessliches Geschmackserlebnis mit Suchtpotential. 
            {/* {documentToReactComponents(content.landwirtschaftText.raw, parseOptions)} */}
          </p>
          {/* <a className="menu-link" href="https://fahr-landwirtschaft.ch" rel="noopener noreferrer">mehr dazu</a> */}

        </Col>
      </Row>
    </Container>

    {/* <div className="parallax4"></div> */}
    <Parallax
      strength={300}
      className="react-parallax"
      bgImage={width < 768 ? Parallax4Mobile : Parallax4}
      bgImageAlt='Aktuell'

    />

    <Container>
      <Row>
        <Col xl="6" lg="6">

        <h2 className="sec-titel">Direkthandel </h2>
        </Col>
        
        <Col xl="6" lg="6">
          <p className="cont-desc">
          Von der Farm bis zur Tasse: Auf 1'500 Metern über Meer werden
           unsere Arabica Kaffees am Rande des Bidoup Nui Ba Nationalparks angebaut
            und bis zum gerösteten Endprodukt weiterverarbeitet. Durch die enge 
            Zusammenarbeit mit mehr als 40 Kaffeebauern der Ethnie der K'Ho, die alle der Chappi 
            Mountains Arabica Kooperative angehören, können die Anforderungen an höchste Qualität 
            auf allen Stufen der Wertschöpfungskette optimal umgesetzt werden.

            {/* {documentToReactComponents(content.trotteText.raw, parseOptions)} */}
          </p>
          {/* <a className="menu-link" href="https://fahr-event.ch" rel="noopener noreferrer">mehr dazu</a> */}

        </Col>
      </Row>
    </Container>

    {/* <div className="parallax5"></div> */}
    <Parallax
      strength={300}
      className="react-parallax"
      bgImage={width < 768 ? Parallax5Mobile : Parallax5}
      bgImageAlt='Aktuell'

    />

    <Container>
      <Row>
        <Col xl="6" lg="6">
          <h2 className="sec-titel">
          Volle Wertschöpfung im Ursprungsland

             </h2>
        </Col>
        
        <Col xl="6" lg="6">
          <p className="cont-desc">
          In einer strukturschwachen und ärmlichen Gegend des vietnamesischen Hochlandes konnten durch das
           Projekt viele neue Arbeitsplätze geschaffen werden – in der Kaffeeverarbeitung, im Betrieb unserer
            Chappi Mountains Bungalows mit eigenem Restaurant und Kaffee, 
          und in Verbindung mit unserem Angebot von Ökotourismus Aktivitäten und Kaffeetouren.
            {/* {documentToReactComponents(content.gastronomieText.raw, parseOptions)} */}
          </p>
            {/* <Link className="menu-link" to="/gastronomie"> mehr dazu</Link> */}
        </Col>
      </Row>
    </Container>
   

<Parallax
      strength={300}
      className="react-parallax"
      bgImage={width < 768 ? Parallax6Mobile : Parallax6}
      bgImageAlt='Aktuell'

    />



<Container>
      <Row>
        <Col xl="6" lg="6">
          <h2 className="sec-titel">Mehrfach prämiert </h2>
        </Col>
        
        <Col xl="6" lg="6">
          <p className="cont-desc">
          Unsere Bemühungen, eine hohe Produktqualität mit sozial verantwortungsvollen Geschäftspraktiken
           und einer nachhaltigen Produktionsweise zu verbinden, wurden mit verschiedenen Auszeichnungen prämiert: 
           Vietnam National Golden Brand,
           Special Product from Dalat, One Commune One Product und Special Rural Product.
            {/* {documentToReactComponents(content.seminarrumeText.raw, parseOptions)} */}
          </p>
            {/* <Link className="menu-link" to="/seminar-raum"> mehr dazu</Link> */}
        </Col>
      </Row>
    </Container>




          

    <Parallax
      strength={300}
      className="react-parallax"
      bgImage={width < 768 ? Parallax7Mobile : Parallax7}
      bgImageAlt='Aktuell'

    />



    <Container>
      <Row>
        <Col xl="6" lg="6">
          <h2 className="sec-titel">Coming soon..</h2>
        </Col>
        
        <Col xl="6" lg="6">
          <p className="cont-desc">
          Unsere Produkte werden ab Ende Oktober in der Schweiz in den Handel kommen,
           ab August werden wir Eure Vorbestellungen entgegennehmen, damit der Kaffee maximal frisch bei Euch zuhause ankommt.
           Mit jeder Packung und jeder Tasse unterstützt Ihr unser Projekt und damit die Kaffeebauern und ihre Familien!
            {/* {documentToReactComponents(content.aboutText.raw, parseOptions)} */}
          </p>
          {/* <Link className="menu-link" to="/das-team"> mehr über uns</Link> */}

        </Col>
      </Row>
    </Container>

    {/* <div className="parallax7"></div> */}
    <Parallax
      strength={300}
      className="react-parallax"
      bgImage={width < 768 ? Parallax8Mobile : Parallax8}
      bgImageAlt='Aktuell'

    />

      <Container className="prod-cont" style={{paddingTop:'20px'}}>
      <Row>
        <Col xl="6" lg="6" md="6" sm="12" className="col1-prod">
          <img className="prod-img" src={arabica} />
        </Col>
        
        <Col xl="6" lg="6" md="6" sm="12" className="col2-prod">
        <h2 className="prod-titel">Arabica – Mild, blumig, fruchtig</h2>
          <p className="prod-desc">
          Unser Arabica –
           eine Mischung handgepflückter Sorten garantiert mit seiner feinen Ausgewogenheit 
           zu jeder Tageszeit ein sinnliches Geschmackserlebnis – Genuss pur!
          </p>
          {/* <Link className="menu-link" to="/das-team"> mehr über uns</Link> */}

        </Col>
      </Row>
    </Container>
    <Container className="prod-cont">
      <Row>
        <Col xl="6" lg="6" md="6" sm="12" className="col1-prod">
          <img className="prod-img" src={rob} />
        </Col>
        
        <Col xl="6" lg="6" md="6" sm="12" className="col2-prod">
        <h2 className="prod-titel">Robusta – Kräftig, würzig, schokoladig</h2>
          <p className="prod-desc">
          Unser Robusta – ein starker, intensiver Frühstückskaffee mit einer extra Portion Koffein
           für den optimalen Start in den Tag! Und dazu prädestiniert, 
           jedem Arabica Kaffee durch die Beimischung eine kräftigere Note zu verleihen.
          </p>
          {/* <Link className="menu-link" to="/das-team"> mehr über uns</Link> */}

        </Col>
      </Row>
    </Container>
    <Container className="prod-cont" >
      <Row>
        <Col xl="6" lg="6" md="6" sm="12" className="col1-prod">
          <img className="prod-img" src={blend} />
        </Col>
        
        <Col xl="6" lg="6" md="6" sm="12" className="col2-prod">
        <h2 className="prod-titel">Blend – Ausgewogen, fruchtig, schokoladig</h2>
          <p className="prod-desc">
          Unsere Hausmischung – eine sorgfältig ausbalancierte
           Kombination von Arabica und Robusta Kaffees, die das Herz eines
            jeden Kaffeeliebhabers höher schlagen lässt – beschwingend zu jeder Tageszeit!
          </p>
          {/* <Link className="menu-link" to="/das-team"> mehr über uns</Link> */}

        </Col>
      </Row>
    </Container>

    <Parallax
      strength={300}
      className="react-parallax-last"
      bgImage={width < 768 ? Parallax9Mobile : Parallax9}
      bgImageAlt='Aktuell'

    />


    {/* <section className="top-map">
      <span>Das Kloster Fahr an der Limmat</span>
      <br></br>
      <Link className="AnreiseLink" to="/kontakt">Anreise</Link>
    </section> */}

    {/* {typeof window !== 'undefined' &&
      <LeafletMap
      position={[47.4087048, 8.4393097]} // Your Coordinates
        zoom={15} // Zoom Level
        markerText={"Kloster Fahr"} // Icon text
      />
    } */}
    <Footer />
  </Layout>
  )
}

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
   
     gatsbyImageData(layout: FIXED)
    }
  }
`;

export const pageQuery = graphql`
  query {
    coffeeShop: file(relativePath: { eq: "Landing_TopTitelbild_AD_6672_rgb_web.jpg" }) {
      ...fluidImage
    }
    


  }
`;




export default IndexPage
